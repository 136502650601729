import React, { useState, useEffect } from 'react';
import { App } from '@mb/App';
import { getLocaleData, LocaleData } from '@mb/lib/i18n';
import { ThemeProvider } from '@mui/material';
import { IntlProvider } from 'react-intl';
import { theme } from 'theme';
import { PreviewFiles } from './components/PreviewFiles';
import {
  GalleryImage,
  ProductAttributeJson,
  ProductConfigurableJson,
} from './types/window';
import { fetchProjects, Project } from '../../../../UILibrary';

declare global {
  interface Window {
    productAttributeJson?: ProductAttributeJson;
    productConfigurableJson?: ProductConfigurableJson;
    galleryImages: GalleryImage[];
  }
}

export const SearchResultsContainer = () => {
  const [langData, setLangData] = useState<LocaleData>({
    currentLocale: 'en',
    messages: {},
  });
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    handleLangData();
  }, []);

  const handleLangData = async () => {
    const data = await getLocaleData();
    setLangData(data);
  };

  const loadProjects = () => {
    // Migrate to jotai
    fetchProjects().then((response) => {
      setProjects(response.projects);
    });
  };

  useEffect(() => {
    loadProjects();
  }, []);

  useEffect(() => {
    window.addEventListener('res-create-project', loadProjects);

    return () => {
      window.removeEventListener('res-create-project', loadProjects);
    };
  }, []);

  return (
    <App>
      <ThemeProvider theme={theme}>
        <IntlProvider
          messages={langData?.messages}
          locale={langData?.currentLocale}
          defaultLocale="en"
        >
          <PreviewFiles projects={projects} />
        </IntlProvider>
      </ThemeProvider>
    </App>
  );
};
