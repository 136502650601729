import React from 'react';
import { createRoot } from 'react-dom/client';
import { SearchResultsContainer } from '../../app/SearchResultsContainer';

const container = document.getElementById(
  'digital-sampling-search-results-container',
);
if (!container) {
  throw new Error(
    'Could not find container element with id #digital-sampling-search-results-container',
  );
}
const root = createRoot(container);
root.render(<SearchResultsContainer />);
